import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "balance" ]

  refreshBalance(event) {
    const controller = this
    const userId = document.querySelector("[name='manual_entry[user_id]']").value
    const entryType = document.querySelector("[name='manual_entry[type]']").value
    if (!userId) return

    // Construct URL with params
    let url = this.element.getAttribute("data-manual-entries-balance-url")
    url = new URL(url)
    url.searchParams.append(
      "user_id", userId
    )
    url.searchParams.append(
      "type", entryType
    )
    url.searchParams.append(
      "hours", document.querySelector("[name='manual_entry[hours]']").value
    )

    // Fetch AJAX call and show new balance
    fetch(
      url, {
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => {
      if (response.redirected && response.url.match('sign_in')) {
        location.reload()
      }
      else {
        return response.json()
      }
    }).then((response) => {
      controller.balanceTarget.innerHTML = response.balance
    })
  }
}
