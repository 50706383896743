import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "rules" ]

  newRule(event) {
    const [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)
    this.rulesTarget.insertAdjacentHTML("beforeend", response.rule)
  }
}
