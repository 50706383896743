import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "contracts" ]

  newContract(event) {
    const [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)
    this.contractsTarget.insertAdjacentHTML("afterbegin", response.contract)
  }
}
