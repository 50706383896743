import { Controller } from "@hotwired/stimulus"
import List from "list.js"

export default class extends Controller {
  static targets = [ "checkbox", "submitButton" ]

  checkboxSelected(event) {
    this.submitButtonTarget.disabled =
      !this.checkboxTargets.some(checkbox => checkbox.checked)
  }
}
