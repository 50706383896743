import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "weekendCycle", "tourHoursGuideLabel", "tourHoursNonGuideLabel"
  ]

  toggleEdit(event) {
    this.element.classList.toggle("edit")
    event.preventDefault()
  }

  switchContract(event) {
    const selectedText = event.target.options[event.target.selectedIndex].text

    // Toggle Weekend cycle
    this.weekendCycleTarget.querySelector("select").selectedIndex = 0
    this.weekendCycleTarget.classList.toggle(
      "disabled", selectedText == "Vermittlung"
    )

    // Toggle tour hours label
    this.tourHoursGuideLabelTarget.classList.toggle(
      "d-none", selectedText != "Vermittlung"
    )
    this.tourHoursNonGuideLabelTarget.classList.toggle(
      "d-none", selectedText == "Vermittlung"
    )
  }

  delete(event) {
    this.element.remove()
    event.preventDefault()
  }

  refresh(event) {
    const [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)
    this.element.outerHTML = response.user_contract
  }
}
