import { Controller } from "@hotwired/stimulus"

const handleResponseWithTimeout = function(response) {
  if (response.redirected && response.url.match('sign_in')) {
    location.reload()
  }
  else {
    return response.json()
  }
}


export default class extends Controller {

  connect() {
    this.ignoreScrollEvents = false
  }

  disconnect() {
    document.querySelectorAll(".flashout").forEach((element) =>
      element.classList.remove("flashout")
    )
  }

  navigate(event) {
    Turbo.visit(event.target.value)
  }

  navigateDate(event) {
    let url = event.target.getAttribute("data-url")
    url = new URL(url)
    url.searchParams.append(
      "date", event.target.value
    )
    Turbo.visit(url)
  }

  updateRuleValidationModalBody(event) {
    document.querySelector("#rulesModal .modal-body").innerHTML =
      event.target.closest("a").getAttribute("data-modal-body")
  }

  loadNewShiftModalBody(event) {
    document.querySelector("#newShiftModal .modal-body").innerHTML = ""
    fetch(
      event.target.closest("a").getAttribute("data-url"), {
      headers: {
        "Content-Type": "application/json"
      }
    }).then(handleResponseWithTimeout).then((response) => {
      document.querySelector("#newShiftModal .modal-body").innerHTML =
        response.modal_body
      document.querySelector("#newShiftModal .user-selection").innerHTML =
        response.modal_body_users
    })
  }

  loadUserSelection(event) {
    let container = document.querySelector("#newShiftModal .user-selection")
    container.innerHTML =
      "<div class=\"py-2 border-top text-muted\">Lädt...</div>"
    this.validateNewForm(event)

    let url = container.getAttribute("data-url")
    url = new URL(url)
    url.searchParams.append(
      "start_at", document.querySelector("[name='shift[start_at]']").value
    )
    url.searchParams.append(
      "end_at", document.querySelector("[name='shift[end_at]']").value
    )

    fetch(
      url, {
      headers: {
        "Content-Type": "application/json"
      }
    }).then(handleResponseWithTimeout).then((response) => {
      container.innerHTML = response.modal_body_users
    })
  }

  loadEditShiftModalBody(event) {
    document.querySelector("#editShiftModal .modal-body").innerHTML = ""
    fetch(
      event.target.closest("a").getAttribute("data-url"), {
      headers: {
        "Content-Type": "application/json"
      }
    }).then(handleResponseWithTimeout).then((response) => {
      document.querySelector("#editShiftModal .modal-body").innerHTML =
        response.modal_body
    })
  }

  loadLockedShiftModalBody(event) {
    document.querySelector("#lockedShiftModal .modal-body").innerHTML = ""
    fetch(
      event.target.closest("a").getAttribute("data-url"), {
      headers: {
        "Content-Type": "application/json"
      }
    }).then(handleResponseWithTimeout).then((response) => {
      document.querySelector("#lockedShiftModal .modal-body").innerHTML =
        response.modal_body
    })
  }

  changeShiftType(event) {
    const aliquotTypes = event.target.getAttribute("data-aliquot-types")
    const tab = event.target.closest(".tab-pane")
    tab.querySelector(".aliquot-time").classList.toggle(
      "d-none", !aliquotTypes.includes(event.target.value)
    )
    tab.querySelector(".dynamic-start-time").classList.toggle(
      "d-none", aliquotTypes.includes(event.target.value)
    )
    tab.querySelector(".dynamic-end-time").classList.toggle(
      "d-none", aliquotTypes.includes(event.target.value)
    )
  }

  loadDayNoteModalBody(event) {
    document.querySelector("#dayNoteModal .modal-body").innerHTML = ""
    fetch(
      event.target.closest("a").getAttribute("data-url"), {
      headers: {
        "Content-Type": "application/json"
      }
    }).then(handleResponseWithTimeout).then((response) => {
      document.querySelector("#dayNoteModal .modal-body").innerHTML =
        response.modal_body
    })
  }

  loadPublicCommentModalBody(event) {
    document.querySelector("#publicCommentModal .modal-body").innerHTML = ""
    fetch(
      event.target.closest("a").getAttribute("data-url"), {
      headers: {
        "Content-Type": "application/json"
      }
    }).then(handleResponseWithTimeout).then((response) => {
      document.querySelector("#publicCommentModal .modal-body").innerHTML =
        response.modal_body
    })
  }

  validateNewForm(event) {

    // Get selected user
    const selectedUser = document.querySelector(
      "#newShiftModal input[name='shift[user_id]']:checked"
    )

    // Get optional shift length
    let fixedShiftLength = null
    if (selectedUser != null) {
      fixedShiftLength = selectedUser.getAttribute("data-fixed-shift-length")
      document.querySelector(".fixed-shift-length").innerHTML =
        "Fixe Dienstlänge: " + fixedShiftLength + "h"
    }

    // Display or hide fixed shift length panels
    document.querySelector(".fixed-shift-length").classList.toggle(
      "d-none", !fixedShiftLength
    )
    document.querySelector(".no-fixed-shift-length").classList.toggle(
      "d-none", fixedShiftLength
    )

    // Set submit button state
    document.querySelector("#newShiftModal input[type='submit']").
      toggleAttribute("disabled", selectedUser == null)
  }

  scrollDates(event) {

    // Prevent loop effects
    const ignore = this.ignoreScrollEvents
    this.ignoreScrollEvents = false
    if (ignore) return
    this.ignoreScrollEvents = true

    // Make dates scroll to table scroll position
    document.querySelector(".date-row").scrollLeft =
      event.target.scrollLeft
  }

  scrollTable(event) {

    // Prevent loop effects
    const ignore = this.ignoreScrollEvents
    this.ignoreScrollEvents = false
    if (ignore) return
    this.ignoreScrollEvents = true

    // Make table scroll to dates scroll position
    document.querySelector(".table-responsive").scrollLeft =
      event.target.scrollLeft
  }

  updateInfoColumn(event) {
    const [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)

    // Replace info column
    document.querySelector("[data-info-column]").outerHTML = response.html

    // Flashout
    const cells = document.querySelectorAll("[data-info-column] tr")
    cells.forEach((cell) => {
      cell.classList.add("flashout")
    })
  }

  toggleView(event) {

    // Display all
    document.querySelectorAll(
      '[data-view="line"]'
    ).forEach((line) => {
      line.classList.remove("d-none")
    })

    // Display only assigned
    if (event.target.value == "assigned") {
      document.querySelectorAll(
        '[data-view="line"][data-empty]'
      ).forEach((line) => {
        line.classList.add("d-none")
      })

    // Display only unassigned
    } else if (event.target.value == "unassigned") {
      document.querySelectorAll(
        '[data-view="line"]:not([data-empty])'
      ).forEach((line) => {
        line.classList.add("d-none")
      })
    }
  }

  closeModal() {
    document.querySelector('.modal.show a[data-bs-dismiss]').click()
  }

  updateDateCells(event) {
    const [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)

    this.closeModal()

    // Update Day Note
    const dayNoteCellSelector = (
      `[data-date="${response.date}"]` +
      `[data-day]`
    )
    this._updateCell(
      dayNoteCellSelector,
      response.date_cell
    )
  }

  updateTableCells(event) {
    const [data, status, xhr] = event.detail
    const response = JSON.parse(xhr.response)

    // Handle errors
    if (response.errors.length) {
      const errorContainer = event.target.closest("form").
        querySelector(".row-errors")
      errorContainer.classList.remove("d-none")
      errorContainer.innerText = response.errors[0]
      return
    }

    this.closeModal()

    // Update Workspace Info
    const workspaceInfoCellSelector = (
      `[data-workspace-id="${response.workspace_id}"]` +
      `[data-workspace-info]`
    )
    this._updateCell(
      workspaceInfoCellSelector,
      response.workspace_info
    )

    // Update Workspace Value
    const workspaceValueCellSelector = (
      `[data-workspace-id="${response.workspace_id}"]` +
      `[data-date="${response.date}"]`
    )
    this._updateCell(
      workspaceValueCellSelector,
      response.workspace_value
    )

    // Update User Info
    const userInfoCellSelector = (
      `[data-user-id="${response.user_id}"]` +
      `[data-user-info]`
    )
    this._updateCell(
      userInfoCellSelector,
      response.user_info
    )

    // Update User Value
    const userValueCellSelector = (
      `[data-user-id="${response.user_id}"]` +
      `[data-date="${response.date}"]` +
      `[data-view="cell"]`
    )
    this._updateCell(
      userValueCellSelector,
      response.user_value
    )

    // Update User Value as line
    const userValueLineSelector = (
      `[data-user-id="${response.user_id}"]` +
      `[data-date="${response.date}"]` +
      `[data-view="line"]`
    )
    this._updateCell(
      userValueLineSelector,
      response.user_value_as_line
    )
  }

  _updateCell(selector, html) {
    let cell = document.querySelector(selector)
    if (cell != null) {
      let muted = cell.classList.contains("cell-muted")
      cell.outerHTML = html
      cell = document.querySelector(selector)
      cell.classList.add("flashout")
      cell.classList.toggle("cell-muted", muted)
    }
  }
}
