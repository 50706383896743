import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // Usually the turbo cache can make the sidebar wiggle a bit
  // Makes cosmetic changes, such that this does not happen
  connect() {

    // Close all sections
    this.element.querySelectorAll(".nav-item .collapse").forEach((item) => {
      item.classList.remove("show")
    })

    // Only open active section
    const link = this.element.querySelector(".nav-link.active")
    if (link != null)
      link.closest(".collapse").classList.add("show")
  }

  isSafari() {
      var ua = navigator.userAgent.toLowerCase();
      return (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1);
  }

  navtoggle(event) {
    document.body.classList.toggle("js-nav-visible")
    event.target.closest(".sidebar-toggle").classList.toggle("active")

    if (!this.isSafari()) {
      return;
    }

    const header = document.querySelector("div.header");
    const dummyHeader = document.querySelector("div.dummy-header");

    if (header) {
      header.style.visibility = "hidden";
    }

    if (dummyHeader) {
      dummyHeader.style.display = "block";
    }

    setTimeout(() => {
      const header = document.querySelector("div.header");
      const dummyHeader = document.querySelector("div.dummy-header");

      if (header) {
        header.style.visibility = "visible";
      }

      if (dummyHeader) {
        dummyHeader.style.display = "none";
      }

    }, 450)

  }
}
