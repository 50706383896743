import { Controller } from "@hotwired/stimulus"
import ScrollMagic from "scrollmagic"

export default class extends Controller {

  connect() {
    const controller = new ScrollMagic.Controller()
    if (document.querySelector(".scrollable-table-wrapper") != null) {
      new ScrollMagic.Scene({
        triggerElement: ".scrollable-table-wrapper",
        triggerHook: 0,
        offset: "-62px"
      })
      .setClassToggle("body", "js-calendar--scrolled")
      .addTo(controller)
    }
    if (document.querySelector(".sticky-header") != null) {
      new ScrollMagic.Scene({
        triggerElement: ".sticky-header",
        triggerHook: 0,
        offset: "14px"
      })
      .setClassToggle("body", "js-header--scrolled")
      .addTo(controller)
    }
  }

  disconnect() {
    document.body.classList.remove(
      "js-calendar--scrolled", "js-header--scrolled"
    )
  }
}