import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "shield" ]

  connect() {

    // Lazy load validation
    fetch(
      this.shieldTarget.getAttribute("data-url"), {
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => {
      if (response.redirected && response.url.match('sign_in')) {
        location.reload()
      }
      else {
        return response.json()
      }
    }).then((response) => {
      this.shieldTarget.innerHTML = response.shield
      this.shieldTarget.closest("a").setAttribute(
        "data-modal-body", response.modal_body
      )
    })
  }
}
