import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "search", "scopes", "consequences", "rule" ]

  search(event) {
    const searchValue = this.searchTarget.value.toLowerCase()
    const scopeValue = this.scopesTarget.value
    const consequenceValue = this.consequencesTarget.value

    // Iterate rules and show only matches
    this.ruleTargets.forEach((rule) => {
      rule.classList.toggle(
        "d-none", !(

          // Search
          (
            searchValue == "" ||
            rule.getAttribute("data-type").toLowerCase().
              includes(searchValue) ||
            rule.getAttribute("data-note").toLowerCase().
              includes(searchValue) ||
            rule.querySelector("[data-title]").innerText.toLowerCase().
              includes(searchValue)

          // Scopes
          ) && (
            scopeValue == "" ||
            Array.prototype.some.call(
              rule.querySelectorAll("[data-scope]"), (scope) => {
                return scope.getAttribute("data-scope") == scopeValue
              }
            )

          // Consequences
          ) && (
            consequenceValue == "" ||
            Array.prototype.some.call(
              rule.querySelectorAll("[data-consequence]"), (consequence) => {
                return (
                  consequence.getAttribute("data-consequence") ==
                  consequenceValue
                )
              }
            )
          )
        )
      )
    })
  }
}
